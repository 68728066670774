import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';

const WhatsAppPassItOn = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="*I'M A VOTER*"
      description="Record numbers registering. This election matters 🌍"
      shareImage="pass-it-on"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`👉 *PASS IT ON* 👉
👉 Send to 5 mates now👉
👉 https://saveourfuture.uk/pass-it-on 👉

🤓 INFO
🚨 REGISTER B4 TUE 26 NOV 🚨
🤓 Everyone's voting. Don't be _that_ gal/guy
🤓 Register at uni & home (vote at one)
🤓 Postal voting _is_ luxury voting
🤓 https://saveourfuture.uk/register (3 mins)


🤖 *GET A VOTEBOT*
🤖 Chatbot with voting assistant
🤖 https://saveourfuture.uk/votebot

For more democracy tips like this, sign up here:
https://saveourfuture.uk `}
    />
    <Interstitial />
  </Layout>
);

WhatsAppPassItOn.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppPassItOn;

export const query = graphql`
  query WhatsAppPassItOnQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
